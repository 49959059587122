import './AboutProduct.css'

export const AboutProduct = () => {
  return (
    <section className='aboutProduct'>
      <ul className='aboutProduct-list'>
        <li className='aboutProduct-item item-color'>
          <img className='about-img' src={require('../../Images/aboutProduct1.jpg')}alt="Тканина" />
          <h4 className='aboutProduct-listTitle'>Тканина</h4>
          <p className='aboutProduct-listText'>Сорочка приємна до тіла та дозволяє шкірі дихати, а завдяки доданому поліестеру робить сорочку довговічною та стійкою до зминання, структура м'яка та та гарно струїться додаючи образу трохи легкості та романтичності.</p>
        </li>
        <li className='aboutProduct-item'>
          <img className='about-img' src={require('../../Images/aboutProduct2.jpg')} alt="Рукава" />
          <h4 className='aboutProduct-listTitle'>Рукава</h4>
          <p className='aboutProduct-listText'>Рукав прямий та має приховану пительку завдяки якій, можна вкоротити та створити ніжні хвилі, що робить кість руки жіночнішою, додає сорочці невимушеної, елегантності та зручності. Достатньо вільні, зручні та можна сказати ексклюзивні.</p>
        </li>
        <li className='aboutProduct-item item-color'>
          <img className='about-img' src={require('../../Images/aboutProduct3.jpg')} alt="Фасон" />
          <h4 className='aboutProduct-listTitle'>Фасон</h4>
          <p className='aboutProduct-listText'>Злегка подовжена спинка додає невимушеності образу та допоможе прибрати недоліки фігури візуально зменшуючи сідниці та стегна.</p>
        </li>
        <li className='aboutProduct-item '>
          <img className='about-img' src={require('../../Images/aboutProduct4.jpg')}alt=" Універсальність" />
          <h4 className='aboutProduct-listTitle'> Універсальність</h4>
          <p className='aboutProduct-listText'>Завдяки продуманому та сучасному фасону сорочки один і той самий розмір може чудово сидіти на різних параметрах створюючи різний силует фігури не втрачаючи чудовий вигляд.</p>
        </li>
        <li className='aboutProduct-item item-color'>
          <img className='about-img' src={require('../../Images/aboutProduct5.jpg')} alt="Комірець" />
          <h4 className='aboutProduct-listTitle'>Комірець</h4>
          <p className='aboutProduct-listText'>Класичної форми з переходом у стильний v-подібний виріз та складочку котра закріплена декоративними гудзиками і створює акцент та розширення сорочки до стегон.</p>
        </li>
      
      </ul>
    </section>
  );
};

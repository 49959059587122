import './ProductSize.css';

export const ProductSize = () => {
  return (
    <section id="productSize" className='productSize'>
      <h2>Розмірна сітка та заміри виробу</h2>
      <table className='productSize-table'>
  <thead>
    <tr>
      <th>Розмір</th>
      <th>42-44</th>
      <th>46-48</th>
      <th>50-52</th>
      <th>54-56</th>
      </tr>
  </thead>
  <tbody>
    <tr>
      <td>Обхват грудей виробу, см</td>
      <td>108</td>
      <td>114</td>
      <td>124</td>
      <td>134</td>
    </tr>
    <tr>
    <td>Ваш обхват грудей, см</td>
      <td>88-103</td>
      <td>104-110</td>
      <td>111-119</td>
      <td>120-130</td>
    </tr>
    <tr>
    <td>Обхват стегон виробу, см</td>
      <td>112</td>
      <td>118</td>
      <td>128</td>
      <td>138</td>
    </tr>
    <tr>
    <td>Довжина, см</td>
      <td>74</td>
      <td>76</td>
      <td>76</td>
      <td>78</td>
    </tr>
    <tr>
    <td>Рукав, см</td>
      <td>53</td>
      <td>53</td>
      <td>54</td>
      <td>55</td>
    </tr>
  </tbody>
</table>

      <p className='productSizeText'><span className='productSize-bolt'>*</span>   Розміри розраховані сидіти вільно а фасон відповідає сучасним трендам, підходе на всі типи фігур та має широку варіативність подачі образу та об'ємності силуету.</p>
      <img className='line' src={require('../../Images/line.png')} alt="line" />
    </section>
  );
};
